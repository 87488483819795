import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

const VitaPage = (props) => {
  const [item] = props?.data?.allMarkdownRemark?.edges;
  const { content = "", title = "" } = item?.node?.frontmatter;
  return (
    <Layout location={"vita"}>
      {title.length > 0 && <h1>{title}</h1>}
      <ReactMarkdown
        className="pt-10 text-sm"
        components={{
          p: ({ node, ...props }) => <div {...props} />,
        }}
        children={content}
      />
    </Layout>
  );
};

export default VitaPage;

export const pageQuery = graphql`
  query vitaPageTemplate {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "vita-page" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            content
          }
        }
      }
    }
  }
`;
